var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-group",
    {
      attrs: {
        group: _vm.group,
        "prepend-icon": _vm.item.icon,
        "sub-group": _vm.subGroup,
        "active-class": `primary white--text`,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _vm.item.sicon
                ? _c(
                    "v-list-item-icon",
                    { staticClass: "sicon" },
                    [
                      _c("v-icon", {
                        domProps: { textContent: _vm._s(_vm.item.sicon) },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(_vm.item.title) },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm._l(_vm.children, function (child, i) {
        return [
          child.children
            ? _c("BaseItemSubGroup", {
                key: `sub-group-${i}`,
                staticClass: "second-dd",
                attrs: { item: child },
              })
            : _c("BaseItem", {
                key: `item-${i}`,
                attrs: { item: child, text: "" },
              }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }