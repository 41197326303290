import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/items',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'item-list',
        name: 'Item-List',
        component: () => import('@/views/items/item-list/ItemList.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Item-New',
        path: 'item-list/item/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/items/item-list/components/FormEditAddItem.vue'),
      },
      {
        name: 'Item-Edit',
        path: 'item-list/item/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/items/item-list/components/FormEditAddItem.vue'),
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/views/items/category/Category.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Item-Category-New',
        path: 'category/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/items/category/components/FormEditAddCategory.vue'),
      },
      {
        name: 'Item-Category-Edit',
        path: 'category/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/items/category/components/FormEditAddCategory.vue'),
      },
    ],
  },
];
