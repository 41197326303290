import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/project',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'batch-production',
        name: 'Batch-Production',
        //component: () => import('@/views/project/batch-production/BatchProduction.vue'),
        component: () => import('@/views/project/batch-production/BatchProduction.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'batch-production-New',
        path: 'batch-production/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/batch-production/components/FormEditAddbatchProduction.vue'),
      },
      {
        name: 'batch-production-View',
        path: 'batch-production/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/batch-production/components/ViewProduction.vue'),
      },
      {
        name: 'Batch-Production-Edit-New',
        path: 'batch-production/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/batch-production/components/FormEditNewbatchProduction.vue'),
      },

      {
        path: 'project',
        name: 'Project',
        //component: () => import('@/views/project/project/Project.vue'),
        component: () => import('@/views/project/project/Project.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Project-New',
        path: 'project/project/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/project/components/FormEditAddProject.vue'),
      },
      {
        name: 'Project-Edit',
        path: 'project/project/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/project/components/FormEditAddProject.vue'),
      },

      {
        name: 'Location',
        path: 'location',
        component: () => import('@/views/project/location/Location.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Location-New',
        path: 'location/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/location/components/FormEditAddLocation.vue'),
      },
      {
        name: 'Location-Edit',
        path: 'location/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/location/components/FormEditAddLocation.vue'),
      },
      {
        name: 'Location-View',
        path: 'location/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/location/components/ViewLocation.vue'),
      },
    ],
  },
  {
    name: 'Batch-Production-Main',
    component: () => import('@/layouts/print-layout/Printlayout.vue'),
    path: '/',
    children: [
      {
        path: '/project/batch-production/production/print',
        name: 'Batch-Production-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/project/batch-production/components/PrintProduction.vue'),
      },
    ],
  },
];
