import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/pos',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'point-of-sale',
        name: 'Point of Sale',
        component: () => import('@/views/point-of-sale/PointOfSale.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
    ],
  },
];
