import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/inventory',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'inventory-management',
        name: 'Inventory-Management',
        component: () => import('@/views/inventory/inventory-management/InventoryManagement.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'inventory-edit',
        name: 'Inventory-Edit',
        component: () => import('@/views/inventory/inventory-management/components/FormEditAddMovement.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'inventory-view',
        name: 'Inventory-View',
        component: () => import('@/views/inventory/inventory-management/components/ViewInventory.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'stock-movement',
        name: 'Stock-Movement',
        component: () => import('@/views/inventory/stock-movement/StockMovement.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'stock-movement-new',
        name: 'Stock-Movement-New',
        component: () => import('@/views/inventory/stock-movement/components/FormEditAddStock.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'stock-movement-add',
        name: 'Stock-Movement-Add',
        component: () => import('@/views/inventory/stock-movement/components/FormEditAddMovement.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
    ],
  },
];
