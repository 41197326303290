var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "page-breadcrumb" },
    [
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "12", sm: "12" } },
        [
          _c(
            "v-card",
            { staticClass: "px-2 custom-shaodow" },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "transparent pa-0",
                  attrs: { dense: "", flat: "", height: "40px" },
                },
                [
                  _c("v-toolbar-title", { staticClass: "pt-3" }, [
                    _c("span", { staticClass: "text--primary" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-toolbar-title",
                    {
                      staticClass: "toobar-extension transparent pb-3",
                      attrs: { slot: "extension" },
                      slot: "extension",
                    },
                    [
                      _c("v-breadcrumbs", {
                        staticClass: "pa-0",
                        attrs: { items: _vm.breadcrumbs },
                        scopedSlots: _vm._u(
                          [
                            _vm.breadcrumbs
                              ? {
                                  key: "divider",
                                  fn: function () {
                                    return [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : null,
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _vm._t("default"),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }