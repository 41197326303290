var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    {
      attrs: {
        href: _vm.href,
        rel: _vm.href && _vm.href !== "#" ? "noopener" : undefined,
        target: _vm.href && _vm.href !== "#" ? "_blank" : undefined,
        to: _vm.item.to,
        "active-class": ``,
      },
    },
    [
      _vm.item.icon
        ? _c(
            "v-list-item-icon",
            [
              _c("v-icon", {
                domProps: { textContent: _vm._s(_vm.item.icon) },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.title
        ? _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", {
                domProps: { textContent: _vm._s(_vm.item.title) },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }