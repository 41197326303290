import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/purchases',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'purchase-invoice',
        name: 'Purchase-Invoice',
        component: () => import('@/views/purchases/purchase-invoice/PurchaseInvoice.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Purchase-Invoice-View',
        path: 'purchase-invoice/invoice/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-invoice/components/ViewInvoice.vue'),
      },
      {
        name: 'Purchase-Invoice-New',
        path: 'purchase-invoice/invoice/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-invoice/components/FormEditNewInvoice.vue'),
      },
      {
        name: 'Purchase-Invoice-Edit',
        path: 'purchase-invoice/invoice/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-invoice/components/FormEditNewInvoice.vue'),
      },
      {
        name: 'Purchase-Invoice-Payment-New',
        path: 'purchase-invoice/invoice/payment/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-invoice/components/FormEditNewPayment.vue'),
      },
      {
        name: 'Purchase-Invoice-Payment-Edit',
        path: 'purchase-invoice/invoice/payment/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-invoice/components/FormEditNewPayment.vue'),
      },
      {
        path: 'purchase-order',
        name: 'Purchase-Order',
        component: () => import('@/views/purchases/purchase-order/PurchaseOrder.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Purchase-Order-View',
        path: 'purchase-order/order/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-order/components/ViewPurchaseOrder.vue'),
      },
      {
        name: 'Purchase-Order-New',
        path: 'purchase-order/order/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-order/components/FormEditNewPurchaseOrder.vue'),
      },
      {
        name: 'Purchase-Order-Edit',
        path: 'purchase-order/order/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-order/components/FormEditNewPurchaseOrder.vue'),
      },
      {
        path: 'payment-voucher',
        name: 'Payment-Voucher',
        component: () => import('@/views/purchases/payment-voucher/PaymentVoucher.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'vendor-supplier',
        name: 'Vendor-Supplier',
        component: () => import('@/views/purchases/vendor-supplier/VendorSupplier.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Vendor-Supplier-New',
        path: 'vendor-supplier/contact/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/vendor-supplier/components/FormEditAddContact.vue'),
      },
      {
        name: 'Vendor-Supplier-Edit',
        path: 'vendor-supplier/contact/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/vendor-supplier/components/FormEditAddContact.vue'),
      },
    ],
  },
  {
    name: 'Purchases-Print-Main',
    component: () => import('@/layouts/print-layout/Printlayout.vue'),
    path: '/',
    children: [
      {
        path: '/purchases/purchase-invoice/invoice/print',
        name: 'Purchase-Invoice-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-invoice/components/PrintInvoice.vue'),
      },
      {
        path: '/purchases/purchase-order/po/print',
        name: 'Purchase-Order-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/purchase-order/components/PrintPurchaseOrder.vue'),
      },
      {
        path: '/purchases/payment-voucher/voucher/print',
        name: 'Payment-Voucher-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/purchases/payment-voucher/components/PrintPaymentVoucher.vue'),
      },
    ],
  },
];
