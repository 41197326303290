import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const UserProfile = () => import('@/views/settings/UserProfile.vue');
const BusinessProfile = () => import('@/views/settings/BusinessProfile.vue');
const SystemPage = () => import('@/views/settings/System.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/settings/',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'profile/user',
        name: 'UserProfile',
        component: UserProfile,
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'profile/business',
        name: 'BusinessProfile',
        component: BusinessProfile,
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'system',
        name: 'System',
        component: SystemPage,
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'subscription',
        name: 'Subscription',
        component: () => import('@/views/settings/Subscription.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: '/settings/subscription/new',
        name: 'Subscription-New',
        component: () => import('@/views/settings/subscription-component/FormEditAddSubscription.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: '/settings/subscription/view',
        name: 'subscription-View',
        component: () => import('@/views/settings/subscription-component/ViewSubscription.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: '/settings/subscription/payment/new',
        name: 'Subscription-Payment-New',
        component: () => import('@/views/settings/subscription-component/FormEditNewTransaction.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: '/settings/subscription/payment-gateway',
        name: 'payment-gateway',
        component: () => import('@/views/settings/subscription-component/PaymentGatewayFpx.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: '/settings/subscription/receipt',
        name: 'receipt',
        component: () => import('@/views/settings/subscription-component/ViewReceipt.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'helpdesk',
        name: 'HelpDesk',
        component: () => import('@/views/settings/HelpDesk.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'helpdesk/form',
        name: 'HelpDesk-Form',
        component: () => import('@/views/settings/helpdesk-component/FormAddNewTicket.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'helpdesk/list',
        name: 'HelpDesk-List',
        component: () => import('@/views/settings/helpdesk-component/TicketList.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'helpdesk/view',
        name: 'HelpDesk-View',
        component: () => import('@/views/settings/helpdesk-component/ViewTicket.vue'),
        //component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
    ],
  },
];
