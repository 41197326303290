import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
//import account from '@/router/account';
//import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';

// Custom code - Wdzul
import { Authority } from '@/shared/security/authority';

import goTo from 'vuetify/es5/services/goto';
import NProgress from 'nprogress';

import bookKeeping from '@/router/book-keeping';
import settings from '@/router/settings';
import inventory from '@/router/inventory';
import pos from '@/router/pos';
import sales from '@/router/sales';
import purchases from '@/router/purchases';
import humanResources from '@/router/human-resources';
import project from '@/router/project';
import items from '@/router/items';
import reports from '@/router/reports';
import init from '@/router/init';

const Login = () => import('@/views/authentication/Login.vue');
const Register = () => import('@/views/authentication/Register.vue');
const Verify = () => import('@/views/authentication/Verify.vue');
const Forgot = () => import('@/views/authentication/Forgot.vue');
const FullLayout = () => import('@/layouts/full-layout/Layout.vue');
const Dashboard = () => import('@/views/dashboard/Dashboard.vue');
// Custom code - end

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL, // enable for production, bust disable when in dev
  // this env has problem
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
        scrollTo = parseInt(to.hash);
    } else if (savedPosition) {
        scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  // custom end
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: FullLayout,
      children: [
        {
          name: "Dashboard",
          path: "/home",
          component: Dashboard,
          meta: { authorities: [Authority.USER, Authority.ADMIN] },
        },
        {
          name: "Dashboard-Classic",
          path: "/home/dashboard-classic",
          component: () => import('@/views/dashboard/classic/Classic.vue'),
          meta: { authorities: [Authority.USER, Authority.ADMIN] },
        },
      ],
    },
    {
      path: '/',
      component: () => import("@/layouts/blank-layout/Blanklayout.vue"),
      children: [
        {
          path: '/login',
          name: 'Login',
          component: Login,
        },
        {
          path: '/register',
          name: 'Register',
          component: Register,
        },
        {
          path: '/verify',
          name: 'Verify',
          component: Verify,
        },
        {
          path: '/forgot',
          name: 'Forgot',
          component: Forgot,
        },
        {
          name: "Home-Second",
          path: "/system",
          component: Home,
          meta: { authorities: [Authority.ADMIN] },
        },
        {
          path: '/forbidden',
          name: 'Forbidden',
          component: Error,
          meta: { error403: true }
        },
        {
          path: '/not-found',
          name: 'NotFound',
          component: Error,
          meta: { error404: true }
        },
      ],
    },
    
    //...account,
    //...admin,
    entities,
    ...pages,
    ...bookKeeping,
    ...pos,
    ...sales,
    ...purchases,
    ...items,
    ...inventory,
    ...project,
    ...humanResources,
    ...reports,
    ...settings,
    ...init
  ]
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
    NProgress.inc(800);
    // Increase randomly
    //var interval = setInterval(function() { NProgress.inc(); }, 1000);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
