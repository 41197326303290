import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/human-resources',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      //Employees
      {
        path: 'employees',
        name: 'Employees',
        component: () => import('@/views/human-resources/employees/Employee.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Employee-New',
        path: 'employee/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/employees/components/FormAddEmployee.vue'),
      },
      {
        name: 'Employee-Edit',
        path: 'employee/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/employees/components/FormEditEmployee.vue'),
      },
      {
        name: 'Employee-View',
        path: 'employee/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/employees/components/ViewEmployee.vue'),
      },
      //Departments
      {
        path: 'departments',
        name: 'Departments',
        component: () => import('@/views/human-resources/departments/Departments.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Departments-New',
        path: 'departments/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/departments/components/FormEditAddDepartment.vue'),
      },
      {
        name: 'Departments-Edit',
        path: 'departments/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/departments/components/FormEditAddDepartment.vue'),
      },
      //Leave Application
      {
        path: 'leave-application',
        name: 'Leave-Application',
        component: () => import('@/views/human-resources/leave-application/LeaveApplication.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Leave-Application-New',
        path: 'leave-application/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/leave-application/components/FormEditAddLeaveApplication.vue'),
      },
      {
        name: 'Leave-Application-Edit',
        path: 'leave-application/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/leave-application/components/FormEditAddLeaveApplication.vue'),
      },
      {
        name: 'Leave-Application-View',
        path: 'leave-application/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/leave-application/components/ViewLeaveApplication.vue'),
      },
      {
        name: 'Leave-Application-Calendar',
        path: 'leave-application/calendar',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/leave-application/components/LeaveCalendar.vue'),
      },

      //Attendance
      {
        path: 'attendance',
        name: 'Attendance',
        //component: () => import('@/views/human-resources/attendance/Attendance.vue'),
        component: () => import('@/views/human-resources/attendance/Attendance.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },

      {
        name: 'Attendance-New',
        path: 'attendance/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/human-resources/attendance/components/FormAddAttendance.vue'),
      },
    ],
  },
];
