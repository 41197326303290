import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/reports',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'general-ledger',
        name: 'General-Ledger',
        component: () => import('@/views/reports/general-ledger/GeneralLedger.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'general-ledger-test',
        name: 'General-Ledger-Test',
        component: () => import('@/views/reports/general-ledger/components/GeneralLedger.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'cash-flow',
        name: 'Cash-Flow',
        component: () => import('@/views/reports/cash-flow/CashFlow.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'profit-and-loss',
        name: 'Profit-and-Loss',
        component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'trial-balance',
        name: 'Trial-Balance',
        component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'balance-sheet',
        name: 'Balance-Sheet',
        component: () => import('@/views/reports/balance-sheet/BalanceSheet.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'lhdn-tax',
        name: 'LHDN-Tax',
        component: () => import('@/views/dashboard/ComingSoon.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
    ],
  },
];
