import { Authority } from '@/shared/security/authority';

export default [
  {
    path: '/sales',
    component: () => import('@/layouts/full-layout/Layout.vue'),
    children: [
      {
        path: 'sales-invoice',
        name: 'Sales-Invoice',
        component: () => import('@/views/sales/sales-invoice/SalesInvoice.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Sales-Invoice-View',
        path: 'sales-invoice/invoice/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/ViewInvoice.vue'),
      },
      {
        name: 'Sales-Invoice-New',
        path: 'sales-invoice/invoice/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/FormEditNewInvoice.vue'),
      },
      {
        name: 'Sales-Invoice-Edit',
        path: 'sales-invoice/invoice/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/FormEditNewInvoice.vue'),
      },
      {
        name: 'Sales-Invoice-Payment-New',
        path: 'sales-invoice/invoice/payment/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/FormEditNewPayment.vue'),
      },
      {
        name: 'Sales-Invoice-Payment-Edit',
        path: 'sales-invoice/invoice/payment/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/FormEditNewPayment.vue'),
      },
      {
        path: 'quotation',
        name: 'Quotation',
        component: () => import('@/views/sales/quotation/Quotation.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Quotation-View',
        path: 'quotation/quote/view',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/quotation/components/ViewQuotation.vue'),
      },
      {
        name: 'Quotation-New',
        path: 'quotation/quote/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/quotation/components/FormEditNewQuotation.vue'),
      },
      {
        name: 'Quotation-Edit',
        path: 'quotation/quote/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/quotation/components/FormEditNewQuotation.vue'),
      },
      {
        path: 'payment-receipt',
        name: 'Payment-Receipt',
        component: () => import('@/views/sales/payment-receipt/PaymentReceipt.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        path: 'customer',
        name: 'Customer',
        component: () => import('@/views/sales/customer/Customer.vue'),
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
      },
      {
        name: 'Customer-New',
        path: 'customer/contact/new',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/customer/components/FormEditAddContact.vue'),
      },
      {
        name: 'Customer-Edit',
        path: 'customer/contact/edit',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/customer/components/FormEditAddContact.vue'),
      },
    ],
  },
  {
    name: 'Sales-Print-Main',
    component: () => import('@/layouts/print-layout/Printlayout.vue'),
    path: '/',
    children: [
      {
        path: '/sales/sales-invoice/invoice/print',
        name: 'Sales-Invoice-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/PrintInvoice.vue'),
      },
      {
        path: '/sales/sales-invoice/delivery/order/print',
        name: 'Sales-Invoice-Delivery-Order-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/PrintDeliveryOrder.vue'),
      },
      {
        path: '/sales/quotation/quote/print',
        name: 'Quotation-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/quotation/components/PrintQuotation.vue'),
      },
      {
        path: '/sales/payment/receipt/print',
        name: 'Payment-Receipt-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/payment-receipt/components/PrintInvoiceReceipt.vue'),
      },
    ],
  },
  {
    name: 'Share-Sales-Invoice-Main',
    component: () => import('@/layouts/print-layout/Printlayout.vue'),
    path: '/share',
    children: [
      {
        path: 'sales/sales-invoice/invoice/print',
        name: 'Share-Sales-Invoice-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/PrintInvoice.vue'),
      },
      {
        path: 'sales/sales-invoice/delivery/order/print',
        name: 'Share-Sales-Invoice-Delivery-Order-Print',
        meta: { authorities: [Authority.ADMIN, Authority.USER] },
        component: () => import('@/views/sales/sales-invoice/components/PrintDeliveryOrder.vue'),
      },
    ],
  },
];
