var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseItemGroup", {
    staticClass: "xyz",
    attrs: {
      item: _vm.item,
      text: "",
      "prepend-icon": _vm.item.icon,
      "sub-group": "",
      "append-icon": "mdi-menu-down",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }