var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    {
      class: `${
        !_vm.$vuetify.breakpoint.smAndDown ? "full-sidebar" : "mini-sidebar"
      }`,
      attrs: { id: "app" },
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }